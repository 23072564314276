import * as React from "react";
import { Link } from "gatsby";
import SEO from "../components/Seo";
import Layout from "../components/Layout";
import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";
import { linkResolver } from "../utils/LinkResolver";
import BlogPage from "./blog/{prismicBlogPosts.uid}";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="Page Not Found" />
      <h1>404 - Page Not Found</h1>
      <p>Sorry we could not find the page you were looking for.</p>
      <p>
        <Link to="/">Go home</Link>.
      </p>
    </Layout>
  );
};

// export default withPrismicUnpublishedPreview(NotFoundPage);

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: "benwulfphotography",
    linkResolver,
    componentResolver: componentResolverFromMap({
      blog_posts: BlogPage,
    }),
  },
]);
